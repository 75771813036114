<template>
    <div class="mx-auto flex max-w-screen-xl items-start gap-8">
        <div class="w-full">
            <div class="mt-8 grid grid-cols-[1fr,min-content,1fr] gap-8">
                <nuxt-link
                    v-for="facility in facilities"
                    :key="facility.label"
                    :to="facility.route"
                    class="col-start-2"
                >
                    <prime-button
                        class="w-full whitespace-nowrap px-16 py-4 !text-3xl"
                        :label="facility.label"
                    />
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    type ActiveFacility,
    definePageMeta,
    provideActiveFacility,
    useViewer
} from '#imports'
import { useQuery } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import { computed, ref } from 'vue'
import PrimeButton from 'primevue/button'
import NuxtLink from '#app/components/nuxt-link'
import { useHead } from 'unhead'
import { useRouteLink } from '~/composables/routing'
import { useRpQuery } from '~/composables/graphql'
import { provideViewer } from '~/composables/use-login'

definePageMeta({
    layout: 'org'
})

const query = useRpQuery({ orgLevel: true })
// TODO: when there are more than 100 facilities
// TODO: Limit to active facilities
const { data, suspense } = useQuery({
    queryKey: ['LandingPageQuery'],
    queryFn: () =>
        query(
            graphql(/* GraphQL */ `
                query LandingPageQuery {
                    organization {
                        code
                        shortName
                        longName
                        logo
                        topBarLink
                        topBarText
                        contactUsLinkText
                    }
                    facilities(first: 100) {
                        edges {
                            node {
                                id
                                shortName
                                longName
                                slug
                                active
                                storefront {
                                    id
                                }
                            }
                        }
                    }
                }
            `)
        )
})

const { facilityHomeLink } = useRouteLink()
const facilities = computed(() => {
    return (
        data.value?.facilities.edges
            .filter(edge => {
                return (
                    edge.node.active === true && edge.node.storefront !== null
                )
            })
            .map(edge => ({
                label: edge.node.shortName,
                route: facilityHomeLink(edge.node.slug.toLowerCase())
            })) ?? []
    )
})

const organization = computed(() => data.value?.organization)

await Promise.all([suspense(), provideViewer()])
const viewer = useViewer()
if (viewer.value !== undefined) {
    const homeFacility = ref<ActiveFacility>(viewer.value.homeFacility)
    provideActiveFacility(homeFacility)
}

useHead({
    title: organization.value?.longName
})
</script>
